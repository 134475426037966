<template>
  <div class="container">
    <el-row class="business_container">
      <el-col class="left_nav hidden-xs-only" :sm="4" :md="4" :lg="4" :xl="4">
        <ul>
          <li
            @click="switchTab(item.id)"
            v-for="item in businessList"
            :key="item.id"
            :class="navIndex == item.id ? 'active' : ''"
          >
            {{
              language == 'cn'
                ? item.cn_title
                : language == 'jp'
                ? item.jp_title
                : language == 'en'
                ? item.en_title
                : ''
            }}
          </li>
        </ul>
      </el-col>
      <!-- <el-col class="top_title hidden-sm-and-up">
        {{ $t('lang.business_detail') }}
      </el-col> -->
      <el-col class="top_nav hidden-sm-and-up">
        <el-select v-model="selectValue" @change="selectChange">
          <el-option
            v-for="item in businessList"
            :key="item.id"
            :label="
              language == 'cn'
                ? item.cn_title
                : language == 'jp'
                ? item.jp_title
                : language == 'en'
                ? item.en_title
                : ''
            "
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-col>
      <el-col
        class="right_content"
        :xs="24"
        :sm="20"
        :md="20"
        :lg="20"
        :xl="20"
      >
        <div class="contentBox">
          <el-row v-for="item in businessDetailList" :key="item.id">
            <img ref="bannerPic" v-lazy="item.image" alt="" />
            <h3>
              {{
                language == 'cn'
                  ? item.cn_title
                  : language == 'jp'
                  ? item.jp_title
                  : language == 'en'
                  ? item.en_title
                  : ''
              }}
            </h3>
            <div v-if="language == 'cn'">
              <p v-for="(childItem, index) in item.cn_content.split('\r\n')" :key=index>{{childItem}}</p>
            </div>
            <div v-if="language == 'jp'">
              <p v-for="(childItem, index) in item.jp_content.split('\r\n')" :key=index>{{childItem}}</p>
            </div>
            <div v-if="language == 'en'">
              <p v-for="(childItem, index) in item.en_content.split('\r\n')" :key=index>{{childItem}}</p>
            </div>

          </el-row>
        </div>
        <div class="page">
          <el-pagination
            background
            @current-change="handleCurrentChange"
            :current-page.sync="introduceForm.page"
            :page-size="introduceForm.limit"
            layout="prev, pager, next"
            :total="totalNum"
          >
          </el-pagination>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { sourceBusiness } from '../api/index'
export default {
  data() {
    return {
      navIndex: window.sessionStorage.getItem('business_item')
        ? window.sessionStorage.getItem('business_item')
        : '',
      language: window.sessionStorage.getItem('language')
        ? window.sessionStorage.getItem('language')
        : 'jp',
      businessList: [],
      businessDetailList: [],
      totalNum: 0,
      introduceForm: {
        id: this.$route.query.id,
        parent_id: window.sessionStorage.getItem('business_item')
          ? window.sessionStorage.getItem('business_item')
          : '',
        page: 1,
        limit: 8
      },
      selectValue: window.sessionStorage.getItem('business_item')
        ? window.sessionStorage.getItem('business_item')
        : ''
    }
  },

  created() {
    this.getBusinessList()
    this.getBusinessDetail()
  },
  updated() {
    var that = this
    that.picChange()
    window.onresize = () => {
      return (() => {
        that.picChange()
      })()
    }
  },
  destroyed() {
    window.onresize = null
  },
  methods: {
    picChange() {
      var banner = this.$refs.bannerPic
      if (document.body.clientWidth >= 768) {
        if (banner) {
          banner.forEach(item => {
            item.style.width = document.body.clientWidth / 8 + 'px'
            item.style.height = document.body.clientWidth / 8 + 'px'
          })
        }
      } else {
        if (banner) {
          banner.forEach(item => {
            item.style.width = document.body.clientWidth / 4 + 'px'
            item.style.height = document.body.clientWidth / 4 + 'px'
          })
        }
      }
    },
    getBusinessList() {
      sourceBusiness({ parent_id: 0 })
        .then(res => {
          if (res.code == '20000') {
            this.businessList = res.data.list
          }
        })
        .catch(err => {
          console.log(err)
        })
    },

    getBusinessDetail() {
      sourceBusiness(this.introduceForm)
        .then(res => {
          if (res.code == '20000') {
            this.businessDetailList = res.data.list
            this.totalNum = Number(res.data.count)
          }
        })
        .catch(err => {
          console.log(err)
        })
    },
    switchTab(id) {
      window.sessionStorage.setItem('business_item', id)
      this.introduceForm.parent_id = id
      this.navIndex = id
      this.getBusinessDetail()
    },
    selectChange(val) {
      window.sessionStorage.setItem('business_item', val)
      this.introduceForm.parent_id = val
      this.selectValue = val
      this.getBusinessDetail()
    },
    handleCurrentChange(val) {
      this.currentPage = val
      this.getBusinessDetail()
    }
  }
}
</script>

<style lang="scss" scoped>
@media screen and (min-width: 768px) {
  .container {
    width: 100%;
    .business_container {
      display: flex;
      width: 80%;
      margin: 107px auto;
      .left_nav {
        //   width: 100%;
        ul li {
          cursor: pointer;
          color: #000000;
          font-size: 18px;
          font-weight: 500;
          letter-spacing: 1.13px;
          margin-bottom: 34px;
          padding: 0 9px;
        }
        .active {
          border-left: 3px solid #bc3810;
        }
      }
      .right_content {
        .contentBox {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          .el-row {
            width: 22%;
            margin-bottom: 40px;
            img {
              width: 100%;
              // transition: all 0.3s ease !important;
              &:hover {
                border-bottom: 4px solid #000000;
              }
            }
            h3 {
              // height: 32px;
              color: #000000;
              font-size: 18px;
              font-weight: 500;
              letter-spacing: 1.13px;
              line-height: 38px;
            }
            p {
              // height: 42px;
              color: #000000;
              font-size: 12px;
              font-weight: 500;
              letter-spacing: 1px;
              line-height: 14px;
            }
          }
        }
        .page {
          text-align: center;
        }
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .container {
    width: 100%;
    .business_container {
      width: 88%;
      margin: 0 auto;
      .top_title {
        text-align: center;
        margin: 24px 0 14px 0;
        height: 38px;
        color: #000000;
        font-size: 24px;
        font-weight: 500;
        letter-spacing: 1.5px;
        line-height: 38px;
      }
      .top_nav {
        margin-bottom: 52px;
      }
      .right_content {
        .contentBox {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          .el-row {
            width: 48%;
            margin-bottom: 40px;
            display: flex;
            flex-direction: column;
            align-items: center;
            img {
              width: 100%;
              // transition: all 0.3s ease !important;
              &:hover {
                border-bottom: 3px solid #000000;
              }
            }
            h3 {
              color: #000000;
              font-size: 14px;
              font-weight: 500;
              letter-spacing: 0.88px;
              line-height: 38px;
            }
            p {
              color: #000000;
              font-size: 12px;
              font-weight: 500;
              letter-spacing: 1px;
              line-height: 14px;
            }
          }
        }
        .page {
          margin-bottom: 30px;
          text-align: center;
          margin-bottom: 30px;
        }
      }
    }
  }
}
</style>
