<template>
  <div class="container">
    <el-row class="business_introduce">
      <el-col class="title_introduce">{{ $t('lang.introduce') }}</el-col>
      <el-col class="content_introduce">
        <el-row
          class="picContainer"
          v-for="item in businessList"
          :key="item.id"
          @click.native="goBusinessDetail(item.id)"
        >
          <div class="picBox">
            <img ref="bannerPic" v-lazy="item.image" alt="" />
          </div>
          <p>
            {{
              language == 'cn'
                ? item.cn_title
                : language == 'jp'
                ? item.jp_title
                : language == 'en'
                ? item.en_title
                : ''
            }}
          </p>
        </el-row>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { sourceBusiness } from '../api/index'
export default {
  name: 'introduce',
  data() {
    return {
      language: window.sessionStorage.getItem('language')
        ? window.sessionStorage.getItem('language')
        : 'jp',
      businessList: []
    }
  },
  computed: {},
  created() {
    this.getBusinessList()
  },
  mounted() {},
  updated() {
    var that = this
    that.picChange()
    window.onresize = () => {
      return (() => {
        that.picChange()
      })()
    }
  },
  destroyed() {
    window.onresize = null
  },
  watch: {},
  methods: {
    // 用于控制图片在不同屏幕下的同比例缩放
    picChange() {
      var banner = this.$refs.bannerPic
      if (document.body.clientWidth >= 768) {
        if (banner) {
          banner.forEach(item => {
            item.style.width = document.body.clientWidth / 4 + 'px'
            item.style.height = (document.body.clientWidth * 3) / 16 + 'px'
          })
        }
      } else {
        if (banner) {
          banner.forEach(item => {
            item.style.width = (document.body.clientWidth * 7) / 10 + 'px'
            item.style.height = (document.body.clientWidth * 21) / 40 + 'px'
          })
        }
      }
    },
    getBusinessList() {
      sourceBusiness({ parent_id: 0 })
        .then(res => {
          if (res.code == '20000') {
            this.businessList = res.data.list
          }
        })
        .catch(err => {
          console.log(err)
        })
    },
    goBusinessDetail(business_id) {
      window.sessionStorage.setItem('business_item', business_id)
      this.$router.push({ name: 'introduceDetail', query: { id: business_id } })
    }
  }
}
</script>

<style lang="scss" scoped>
@media screen and (min-width: 768px) {
  .container {
    width: 100%;
    display: flex;
    justify-content: center;
    .business_introduce {
      width: 88%;
      //   background-color: red;
      .title_introduce {
        border-bottom: 1.87px solid rgba(53, 58, 78, 0.15);
        padding: 55px 0 20px 0;
        text-align: center;
        color: #000000;
        font-size: 36px;
        letter-spacing: 2.25px;
        line-height: 38px;
      }
      .content_introduce {
        padding-top: 43px;
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        .picContainer {
          width: 28%;
          .picBox {
            overflow: hidden;
            img {
              width: 100%;
              cursor: pointer;
              transition: all 0.3s ease !important;
              &:hover {
                transform: scale(1.2);
              }
            }
          }
          p {
            cursor: pointer;
            margin: 20px 0 46px 0;
            text-align: center;
            color: #000000;
            font-size: 24px;
            font-weight: 500;
            letter-spacing: 1.5px;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 767px) {
  .container {
    width: 100%;
    display: flex;
    justify-content: center;
    .business_introduce {
      width: 70%;
      //   background-color: red;
      .title_introduce {
        padding: 24px 0 16px 0;
        text-align: center;
        color: #000000;
        font-size: 24px;
        letter-spacing: 1.5px;
        line-height: 38px;
      }
      .content_introduce {
        padding-top: 43px;
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        .picContainer {
          width: 100%;
          .picBox {
            overflow: hidden;
            img {
              width: 100%;
              cursor: pointer;
              transition: all 0.3s ease !important;
              &:hover {
                transform: scale(1.2);
              }
            }
          }
          p {
            cursor: pointer;
            margin: 20px 0 46px 0;
            text-align: center;
            color: #000000;
            font-size: 18px;
            font-weight: 500;
            letter-spacing: 1.13px;
          }
        }
      }
    }
  }
}
</style>
